import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApi, useAsync } from '../../../hooks';
import { useParams } from 'react-router-dom';

const MCEPageContext = createContext();

export const MCEPageContextProvider = ({ children }) => {
  const { policyId, MCEId } = useParams();
  const { getMCE, getMonthlyReportingForMCE } = useApi();

  const [isEditing, setIsEditing] = useState(false);
  const [premiumData, setPremiumData] = useState([]);
  const [MCEDetails, setMCEDetails] = useState(null);
  const [monthlyData, setMonthlyData] = useState(null);
  const [initialMCEData, setInitialMCEData] = useState(null);
  const [showExecuteButton, setShowExecuteButton] = useState(false);
  const [MCEStatus, setMCEStatus] = useState('Draft');

  const handleGetMCE = useCallback(async () => {
    if (!MCEId || !policyId) {
      console.warn('MCEId or policyId is missing.');
      return;
    }
    try {
      const response = await getMCE(policyId, MCEId);
      if (!response) {
        throw new Error('Failed to fetch MCE details.');
      }

      return response;
    } catch (error) {
      window.alert(`Failed to fetch MCE details: An unknown error occurred.`);
      console.error('Error fetching MCE details:', error);
    }
  }, [policyId, getMCE, MCEId]);

  const handleGetMonthly = useCallback(async () => {
    if (MCEId && policyId) {
      try {
        return await getMonthlyReportingForMCE(policyId, MCEId);
      } catch (error) {
        console.error('Failed to fetch monthly reporting data:', error);
        alert(`An error occurred while fetching monthly reporting data.`);
      }
    }
  }, [policyId, getMonthlyReportingForMCE, MCEId]);

  const { value: MCEData, execute: executeMCE } = useAsync(handleGetMCE);
  const { value: mceMonthly, execute: executeMonthly } = useAsync(handleGetMonthly);

  const refreshMCE = useCallback(async () => {
    try {
      await Promise.all([executeMCE(), executeMonthly()]);
    } catch (error) {
      console.error('Failed to refresh MCE data:', error);
      alert('An error occurred while refreshing MCE data. Please try again.');
    }
  }, [executeMCE, executeMonthly]);

  useEffect(() => {
    if (MCEData) {
      setMCEDetails(MCEData);
      setShowExecuteButton(MCEData.status === 'draft');
    }
    if (mceMonthly) {
      setMonthlyData(mceMonthly);
    }
    setInitialMCEData({ monthlyData: JSON.parse(JSON.stringify(mceMonthly)), MCEData: JSON.parse(JSON.stringify(MCEData)) });
  }, [mceMonthly, MCEData]);

  const resetMCE = useCallback(() => {
    const { monthlyData, MCEData } = initialMCEData;
    setMCEDetails({ ...MCEData });
    setMonthlyData({ ...monthlyData });
  }, [initialMCEData]);

  const context = useMemo(
    () => ({
      MCEDetails,
      isEditing,
      setIsEditing,
      refreshMCE,
      monthlyData,
      premiumData,
      setPremiumData,
      setMCEDetails,
      showExecuteButton,
      setShowExecuteButton,
      setMonthlyData,
      resetMCE,
      MCEStatus,
      setMCEStatus,
    }),
    [
      isEditing,
      setIsEditing,
      monthlyData,
      premiumData,
      setPremiumData,
      MCEDetails,
      setMCEDetails,
      showExecuteButton,
      setShowExecuteButton,
      refreshMCE,
      resetMCE,
      MCEStatus,
      setMCEStatus,
    ]
  );

  return <MCEPageContext.Provider value={context}>{children}</MCEPageContext.Provider>;
};

export const useMCEPageContext = () => {
  const context = useContext(MCEPageContext);
  if (!context) {
    throw new Error('usePolicyPageContext must be used within a MCEPageContextProvider');
  }
  return context;
};
